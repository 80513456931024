<template>    
  <div class="flex flex-col min-h-screen">
    <Menubar1 />          
    <main>
      <slot> </slot>
    </main>
    <footer>
      <slot name="footer"> <Footer /> </slot>
    </footer>        
  </div>
</template>